import { Link } from 'gatsby';
import React from 'react'
import { getImage } from '../util'

const SpeakerSummary = ({ speaker, cellSize = "small-12 medium-6 large-4" }) => {
  return (
    <div className={`cell ${cellSize} text-center speakers-tile`}>
      {speaker.fields && speaker.photo ?
        (<Link to={speaker.fields.slug} className="speaker-photo" tabIndex="-1">
          {getImage(speaker.photo, speaker.name)}
        </Link>) : null
      }
      {speaker.fields ?
        (<Link to={speaker.fields.slug}>
          {speaker.name}
        </Link>) : null
      }
      <p>{speaker.jobPosition}</p>
    </div>
  )
}

export default SpeakerSummary