import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { shuffle } from 'lodash';

import Layout from '../components/layout';
import SpeakerSummary from '../components/SpeakerSummary';

export default class EditionSpeakersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { speakerTiles: null };
  }

  componentDidMount() {
    const { data } = this.props;

    if (!data || !data.speakers) return null;

    let { edges: speakers } = data.speakers;

    // gets speakers that are speaking at conference events or will be speaking at conferences (as determined by
    // the type of event they are planning on speaking at)
    speakers = speakers.filter(s => {
      return (
        (s.node.events &&
          s.node.events.some(e => e.eventType.name.includes('Conference'))) ||
        (s.node.eventTypes &&
          s.node.eventTypes.some(et => et.name.includes('Conference'))) ||
        (s.node.eventTypes &&
          s.node.eventTypes.some(et => et.name.includes('Presentation')))
      );
    });
    const speakerTiles = this.speakerTiles(speakers);
    this.setState({ speakerTiles });
  }

  speakerTiles = speakers =>
    speakers.map(({ node: speaker }) => (
      <SpeakerSummary key={speaker.id} speaker={speaker} />
    ));


  render() {
    const { data } = this.props;

    if (!data || !data.speakers) return null;

    const edition = data.edition;
    let speakerTiles = shuffle(this.state.speakerTiles);

    return (
      <Layout>
        <section className="section">
          <Helmet title={`Speakers | ${edition.name}`} />
          <h1 className="page-header">{edition.name} Speakers</h1>
          <div className="speakers-grid">
            <div className="grid-x grid-margin-x speakers-grid-container">
              {speakerTiles.length > 0 && speakerTiles}
            </div>
            {speakerTiles.length < 1 &&
              (<div className="coming-soon-message"> Fluxible speaker annoucements are coming soon, stay tuned!</div>)
            }
          </div>
        </section>
      </Layout>
    );
  }
}

EditionSpeakersPage.propTypes = {
  data: PropTypes.shape({
    allFluxibleSpeakers: PropTypes.shape({
      edges: PropTypes.array,
    }),
    fluxibleEditions: PropTypes.object,
  }),
};

export const editionSpeakersPageQuery = graphql`
  query EditionSpeakersPageQuery($editionId: Int!) {
    speakers: allFluxibleSpeakers(
      filter: { editionId: { eq: $editionId }, name: { ne: "" } }
    ) {
      edges {
        node {
          id
          name
          jobPosition
          fields {
            slug
          }
          photo {
            name
            altText
            contentType
          }
          events {
            name
            eventType {
              name
            }
          }
          eventTypes {
            name
          }
        }
      }
    }
    edition: fluxibleEditions(alternative_id: { eq: $editionId }) {
      alternative_id
      name
      startDate
      endDate
    }
  }
`;
