import React from 'react';
import { v4 } from 'uuid'

import { FluxibleSocialMediaBlock } from '../components/FluxibleIcon'

const normalizeObject = (obj, fields) => {
  const formatedEntity = {};
  fields.forEach(elem => {
    if (typeof elem === 'string') {
      if (obj === null) {
        formatedEntity[elem] = null
      } else {
        formatedEntity[elem] = obj[elem];
      }
    } else if (typeof elem === 'object') {
      Object.keys(elem).forEach(key => Object.assign(formatedEntity, normalizeObject(obj[key], elem[key])));
    }
  });
  return formatedEntity;
}

const normalizeObjectList = (list, fields, key = 'title') => {
  return list.reduce((acc, elem) => {
    const obj = elem.node || elem;
    const formatedEntity = normalizeObject(obj, fields);
    acc[formatedEntity[key]] = formatedEntity;
    return acc;
  }, {});
}

const getUrlVars = (url) => {
  const vars = {};
  if (!url) return vars;

  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}

const getImage = (image, title, klass = '') => {
  if (!image) return null
  const altText = image.altText ? image.altText : title;
  return (
    <img src={getImageUrl(image.name)} alt={altText} className={klass} key={v4()} />
  )
}

const getImageUrl = (image) => {
  let prefix = '';
  if (process.env.GATSBY_MEDIA_URL)
    prefix = process.env.GATSBY_MEDIA_URL;
  return prefix + image;
}

const getSocialMedia = (socialMedia, includeWebsite = true, subject = null) => {
  if (!socialMedia) return null

  const socialMediaType = {
    twitter: 'twitter-square',
    instagram: 'instagram-square',
    linkedin: 'linkedin',
    facebook: 'facebook-square',
  }

  const linksList = socialMedia.map(medium => {
    if (medium.type === 'website' && includeWebsite) {
      return (
        <FluxibleSocialMediaBlock
          key={v4()}
          icon="home"
          text={medium.value}
          link={medium.value}
          alt={subject !== null ? `Website for ${subject}` : 'website'}
        />
      );
    } else if (socialMediaType[medium.type]) {
      return (
        <FluxibleSocialMediaBlock
          key={v4()}
          icon={['fab', socialMediaType[medium.type]]}
          text={medium.value}
          link={medium.value}
          alt={subject !== null ? `${subject} on ${medium.type}` : medium.type}
        />
      );
    }

    return null;
  })

  return <div className="social_media_section">{linksList}</div>
}

export {
  getUrlVars,
  normalizeObject,
  normalizeObjectList,
  getImage,
  getImageUrl,
  getSocialMedia
}
